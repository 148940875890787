import './App.css';
import React, { Component } from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import attention from './assets/attention.gif'
import dancing from './assets/thisfoshure.gif'
import Photos from './components/Photos.js'

const App = () => (
  <div className='app'>
    <Navigation />
    <Main />
  </div>
);

const Navigation = () => (
  <nav>
    <ul>
      <li><NavLink exact activeClassName="current" to='/'>home</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/photos'>photographs</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/contact'>contact</NavLink></li>
    </ul>
  </nav>
);

const Home = () => (
  <div className='about personal'>
    <h1 className="personal landing"> This is the personal website of Kaitlyn Renee Fox. </h1>

    <img src ={dancing} alt="Dancing teeth"/>
</div>
);

const About = () => (
  <>
  <div className='about personal'>
    <p> Hello! My name is Kait Fox.  </p>
      
      <p> I am passionate about building worlds through the use of color, tone, and texture, irrespective of the particular medium. A former student of anthropology and art, I am fascinated by the intersections of technology, aesthetics, and language (and the different forms of of meaning-making that arise as a result of those entanglements). Above all, I consider myself a hobbyist, perpetual student and avowed generalist.
    </p>

    <p> Thanks for visiting, please click around and explore. You can find my resume <a href="https://drive.google.com/file/d/1rbnpUr-6ZmvU4zNJVEx7K5A-exCTYjsx/view?usp=sharing" style = {{color: "black"}}>here.</a> </p>

    <p> This is a single page application that I built using React (and a sprinkle of PHP). All content handmade by me. </p>

    <img src ={attention} alt="Please Give Me Attention"/>
  </div>
</>
);

//  <p style = {{marginTop: "10%"}}> <NavLink exact activeClassName="current" to='/fragments' style = {{color: "black"}}>A secret link</NavLink>, not quite worthy of the navigation bar: fragments and experiments. </p>

const Fragments = () => (
  <div className='home'>
    <h1 className="personal"> fragments </h1>
</div>
);

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      mailSent: false,
      emailError: false,
      badEmail: false,
      blankEmail: false,
      blankName: false,
      blankMessage: false
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  } 

validateEmail = (email) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
 }

resetForm(){
  this.setState({name: '', email: '', message: '', emailError: false, mailSent: true, badEmail: false, blankEmail: false, blankName: false, blankMessage: false});
}

resetFormI(){
  this.setState({emailError: false, mailSent: false, badEmail: false, blankEmail: false, blankName: false, blankMessage: false});
}

 handleFormSubmit( event ) {
  event.preventDefault();
  
  this.resetFormI();

  if (`${this.state.email}` === ''){
    this.setState({blankEmail: true});
  }

  if(`${this.state.name}` === ''){
    this.setState({blankName: true});
  }

  if(`${this.state.message}` === ''){
    this.setState({blankMessage: true});
  }

  var valid = this.validateEmail(`${this.state.email}`);

  if(!valid){
    this.setState({badEmail: true});
  }

  var okToSend = (valid && (!this.state.blankMessage) && (!this.state.blankName));
  console.log(okToSend);
  
if (okToSend){

  console.log(JSON.stringify(this.state));
  fetch('https://velvetspectrograph.com/index.php', {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(
    (response) => {console.log(response); return response}).then((response) => (response.json())
      ).then((response)=> {
        console.log(response);
    if (response.status === 'success') {
      alert("Message Sent.");
      console.log(response);
      this.resetForm();
    } else if(response.status === 'fail') {
      alert("Message failed to send.")
    }
  });
}
}

  render() {
    return (

    <div className='contact personal'>
    <p> shoot me an email: kaitlynrfox@gmail.com </p>
    <p> or write me a note: </p>
    
    <form method="post" action="#">

    <label>Name</label>
  <input type=" text" id="name" name="name" placeholder="name..."
    value={this.state.name}
    onChange={e => this.setState({ name: e.target.value })}
  />

  <label>Email</label>
  <input type="email" id="email" name="email" placeholder="email..."
    value={this.state.email}
    onChange={e => this.setState({ email: e.target.value })}
  />

  <label>Message</label>
  <textarea id="message" name="message" placeholder="write something..."
    onChange={e => this.setState({ message: e.target.value })}
    value={this.state.message}
  ></textarea>

    <input id = "butt" type="submit" onClick={e => this.handleFormSubmit(e)} value="Submit" />
  </form>

  {this.state.emailError && <p id = "email" style = {{marginTop: "5%", textDecoration: "underline"}}> Please enter a valid email address. </p>}
  {this.state.blankName && <p id = "email" style = {{marginTop: "5%", textDecoration: "underline"}}> Please enter a valid name. </p>}
  {this.state.badEmail && <p id = "email" style = {{marginTop: "5%", textDecoration: "underline"}}> Please enter a valid email address. </p>}
  {this.state.blankMessage && <p id = "email" style = {{marginTop: "5%", textDecoration: "underline"}}> Please write a message. </p>}
  {this.state.mailSent && <p id = "email" style = {{marginTop: "5%"}}> Thanks for your message! Kait will respond shortly. {`<3`} </p>}
  </div>
  ); }
}

const Main = () => (
  <Switch>
    <Route exact path='/' component={Home}></Route>
    <Route exact path='/about' component={About}></Route>
    <Route exact path='/photos' component={Photos}></Route>
    <Route exact path='/contact' component={Contact}></Route>
    <Route exact path='/fragments' component={Fragments}></Route>
  </Switch>
);


export default App;
