import flow from '../assets/flowmat.jpg'
import desert from '../assets/desert.jpg'
import toes from '../assets/tucked toes.jpg'
import room from '../assets/spider.jpg'
import ball from '../assets/golden ball.jpg'
import teeth from '../assets/teeth choice b+w.jpg'
import bones from '../assets/bones.jpg'
// import {useElementOnScreen} from '../LazyLoader.js'

export default function Photos (){

//    const [containerRef, isVisible] = useElementOnScreen({
//      root: null,
//      rootMargin: '0px',
//      threshold: 1
//    })
  
    return(
    <>
    <p className = 'about personal'> Some recent work. Thinking about traces and rhyme, probabilities and tendencies, the emergent cleanliness of the abstract. No fully fledged statement yet - I am simply calling it 'dream house.'
    </p>
    <div className='photos'>
      <img src ={flow} className = 'horizontal' loading = "lazy" alt=""/>
      <img src ={room} className = 'horizontal' loading = "lazy" alt=""/>
      <img src ={toes} className = 'vertical' loading = "lazy" alt=""/>
      <img src ={desert} className = 'vertical' loading = "lazy" alt=""/>
      <img src ={ball} className = 'horizontal' loading = "lazy" alt=""/>
      <img src ={bones} className = 'horizontal' loading = "lazy" alt=""/>
      <img src ={teeth} className = 'horizontal' loading = "lazy" alt=""/>
  </div>
  </>);
  
  }
  